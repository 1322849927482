/* Set the position of the footer section to relative */
.Footer-container {
    position: relative;
}

/* Add a horizontal line with light gray color */
.Footer-container>hr {
    border: 1px solid var(--lightgray);
}

/* Style the footer section */
.footer {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}

/* Display social media icons in a row with a gap of 4rem */
.social-links {
    display: flex;
    gap: 4rem;
}

/* Style each social media icon */
.social-links > img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

/* Style the logo */

.logo-f {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo-f>img {
    width: 10rem;
    margin-bottom: 2rem;
}

.f-copyright {
    font-size: 0.8rem;
    color: white;
}




/* Add a red blurred background to the left of the footer section */
.footer-blur-1 {
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: red;
}

/* Add an orange blurred background to the right of the footer section */
.footer-blur-2 {
    bottom: 0;
    left: 15%;
    width: 36rem;
    height: 22rem;
    filter: blur(150px);
    background: rgb(255, 115, 0);
}
