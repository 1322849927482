/* This class defines a flex container for the header element, with items placed
   along the main axis and free space distributed evenly between them */
.header {
    display: flex;
    justify-content: space-between;
}

/* This class sets the dimensions of the logo */
.logo {
    width: 10rem;
    height: 3rem;
}

/* This class styles the header menu, which is an unordered list of links */
.header-menu {
    /* Removes the bullet points from the list items */
    list-style: none;
    /* This makes the menu a flex container */
    display: flex;
    /* Adds a gap of 2rem between menu items */
    gap: 2rem;
    /* Sets the text color to white */
    color: white;
}

/* This rule changes the color and cursor when hovering over a menu item */
.header-menu>li:hover {
    /* Changes the text color to a custom orange color */
    color: var(--orange);
    /* Sets the cursor to a pointer */
    cursor: pointer;
}

/* This media query applies only when the screen width is 768px or less */
@media screen and (max-width: 768px) {
    /* This rule targets the second child element of the header when the screen is small */
    .header>:nth-child(2)
    {
        /* This positions the second child element to the right and 2rem from the edge of the screen */
        position: fixed;
        right: 2rem;
        /* This sets the z-index of the second child element to be on top of other elements */
        z-index: 99;
    }
    /* This rule changes the direction of the flex container to be columnar and applies a background color and padding to the menu */
    .header-menu {
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
    }
}
